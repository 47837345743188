import React from "react";
import { Link } from "gatsby";

// const getCategory = (article) => {
//   let category = "-";

//   const type = article?.node_type?.type;

//   //console.log(article.type)
//   switch (type) {
//     case "blog":
//       category = article.relationships?.category?.name;
//       break;
//     case "ebook":
//       category = article.type;
//       break;

//     default:
//       category = "--";
//       break;
//   }

//   return category;
// };


// const getCategory = (article) => {
//     let category = "-";
  
//     const type = article.__typename;
  
//     //console.log(article.type)
//     switch (type) {
//       case "node_blog":
//         category = article.relationships?.category?.name;
//         break;
//       case "node_ebook":
//         category = article.type;
//         break;
  
//       default:
//         category = "--";
//         break;
//     }
  
//     return category;
//   };


const ArticleCard = ({
  title,
  description,
  path,
  className,
  image,
  category,
  ...props
}) => {
  //console.log(props);

  const uri = path.alias.endsWith("/") ? path.alias : path.alias + "/";
  return (
    <Link to={uri}>
      <div className={className}>
        <div className="h-[300px] md:h-[130px] overflow-hidden    rounded-[10px] relative">
          <img src={image.src} alt={image.alt} />
          <div className="border rounded-[6px] px-2.5 py-2 inline-block bg-white absolute bottom-2.5 left-2.5 text-xs leading-[12px] tracking-[-0.03em]">
            {category}
          </div>
        </div>
        <div className="space-y-[15px] text-[#3D4A5C] px-2.5 md:h-[250px]">
          <div className="lg:text-2xl font-medium lg:leading-[28px] tracking-[-0.02em]">
            {title}
          </div>
          <div className="text-sm  leading-[22px]  ">{description}</div>
        </div>
      </div>
    </Link>
  );
};



export default ArticleCard;
