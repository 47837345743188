import React from "react";
import ParagraphZebraCard from "./zebra-card";

const ParagraphZebra = ({
  data,
  context,
  className,
  classNameCards,
  ...props
}) => {
  
  if ( data.relationships.cards.length === 0  ) return false;
  console.log(className,data,classNameCards)
  
  if( context === "not zebra"){
    return (
      <div className={className}>
        
        <div className="md:hidden grid grid-cols-1 gap-4">
          {data.relationships.cards.map((c, index) => (
            <ParagraphZebraCard data={c} index={index} key={index} />
          ))}
        </div>
        <div
          className={
            "hidden md:block --gap-4 --md:gap-[80px] space-y-12" +
            classNameCards
          }
        >
          {data.relationships.cards.map((c, index) => (
            <ParagraphZebraCard data={c} index={index} key={index} context={context} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={className}>
      {data?.title && (
        <h2 className="text-center text-[48px] md:text-[72px] font-medium leading-[60px] tracking-[-0.05em] max-w-[1000px] mx-auto">
          {data.title}
        </h2>
      )}
      {data?.description?.processed && <div>{data.description.processed}</div>}

      <div className="md:hidden grid grid-cols-1 gap-4">
        {data.relationships.cards.map((c, index) => (
          <ParagraphZebraCard data={c} index={index} key={index} />
        ))}
      </div>
      <div
        className={
          "hidden md:block gap-4 md:gap-[80px] " +
          classNameCards
        }
      >
        {data.relationships.cards.map((c, index) => (
          <ParagraphZebraCard data={c} index={index} key={index} context={context} />
        ))}
      </div>
    </div>
  );
};

export default ParagraphZebra;
