import React from "react";
import ArticleCard from "../components/cards/article";

const ArticlesSection = ({ articles, className, classNameCards, classNameCard }) => {
  return (
    <section className={className}>
      <SectionCardsBody
        className={classNameCards}
        cardClass={classNameCard}
        items={articles.map((article) => ({
          ...article,
          image: {
            ...article.image,
            src: article.relationships?.image?.localFile?.publicURL,
          },
          category: getCategory(article),
        }))}
        Card={ArticleCard}
      />
    </section>
  );
};

const getCategory = (article) => {
  let category = "-";

  const type = article?.node_type?.type;

  //console.log(article.type)
  switch (type) {
    case "blog":
      category = article.relationships?.category?.name;
      break;
    case "ebook":
      category = article.type;
      break;

    default:
      category = "--";
      break;
  }

  return category;
};

const SectionCardsBody = ({ Card, items, className, cardClass }) => {
  const cards = items.map((item, index) => (
    <Card {...item} key={index} index={index} className={cardClass} />
  ));
  return <div className={className}>{cards}</div>;
};



export default ArticlesSection;
