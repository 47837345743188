import { Link } from "gatsby";
import React from "react";
//import { Link } from "gatsby";
import TryOut from "../buttons/tryout";

const ParagraphHeroV3 = ({ data, context, onClick, children, ...props }) => {
  //console.log(data);
  //console.log(context);

  const { title, cta } = data;

  const image = {
    url: data.relationships.image.localFile.publicURL,
    alt: data.image.alt,
  };
  //const description = data?.description?.processed;
  const { className } = props;

  return (
    <div className={className}>
      <div className="space-y-8 md:space-y-[70px]">
        <div className="space-y-8 md:space-y-5">
          <h1
            className="text-center  text-[40px] lg:text-[72px] font-medium leading-[44px] lg:leading-[80px] lg:tracking-[-0.04em] text-[#3D434C] --max-w-[900px] mx-auto"
            dangerouslySetInnerHTML={{
              __html: title.replace("f r", "f  r").replace("  ", "<br/>"),
            }}
          />
        </div>

        <div className="max-w-[1000px] mx-auto">
          <div>{children}</div>
        </div>

        <div className="max-w-[900px] mx-auto relative">
          <img src={image.url} alt={image.alt} />
          {cta && cta.title && (
            <div className="absolute inset-x-0 top-[174px] flex flex-row justify-around items-center ">
              <ButtonOrLink
                {...cta}
                className="bg-[#1A1D21] text-white py-3 px-[30px] rounded-lg"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ButtonOrLink = ({ title, uri, className }) => {
  if (uri === "/tryout" || uri === "/tryout/" ) {
    return <TryOut className={className} check={false} title={title} />;
  }

  return (
    <Link to={uri} className={className}>
      {title}
    </Link>
  );
};

export default ParagraphHeroV3;
